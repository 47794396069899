import React from 'react';

import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import format from 'date-fns/format';
import CustomLink from '../components/CustomLink';

import Layout from '../components/Layout';

import './blog-page.scss';

export const BlogPageTemplate = ({ blog, posts }) => {
	return (
		<>
			<div className="header--page">
				<section className="header--content">
					<div className="header--page-title">
						<a href="/blog">{blog.title}</a>
					</div>
				</section>
			</div>
			<div className="layouts--section">
				<div className="layouts--container__slim">
					<h1 className="space--mb9 text--jumbo">{blog.subtitle}</h1>
					{posts.map((post, idx) => (
						// eslint-disable-next-line react/no-array-index-key
						<div className="space--mb8" key={post.title + idx}>
							<h2 className="space--mb2">
								<CustomLink className="post-name" linkType="internal" linkURL={post.path}>
									{post.title}
								</CustomLink>
							</h2>
							<p className="text--extraSmall text--greyDarker">
								{format(post.date, 'MMMM DD, YYYY  ')}
								<a href={`mailto:${post.author}`}>by {post.author}</a>
							</p>
							<p>{post.summary}</p>

							<p>
								<CustomLink linkType="internal" linkURL={post.path}>
									Read More &rsaquo;
								</CustomLink>
							</p>
						</div>
					))}

					<footer className="layouts--blog-footer">TODO: FOOTER</footer>
				</div>
			</div>
		</>
	);
};

// eslint-disable-next-line react/prefer-stateless-function
class BlogPage extends React.Component {
	render() {
		const { data } = this.props;
		const { frontmatter: blog } = data.blogPageData.edges[0].node;
		const { title: seoTitle, description: seoDescription, browserTitle } = blog.seo;

		const posts = data.posts.edges.map(edge => ({
			path: edge.node.fields.slug.replace('posts/', 'blog/'),
			...edge.node.frontmatter
		}));

		return (
			<Layout>
				<Helmet>
					<meta name="title" content={seoTitle} />
					<meta name="description" content={seoDescription} />
					<title>{browserTitle}</title>
				</Helmet>
				<BlogPageTemplate blog={blog} posts={posts} />
			</Layout>
		);
	}
}

export default BlogPage;

export const pageQuery = graphql`
	query BlogPageQuery {
		blogPageData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "blog-page" } } }) {
			edges {
				node {
					frontmatter {
						title
						subtitle
						seo {
							browserTitle
							title
							description
						}
					}
				}
			}
		}
		posts: allMarkdownRemark(
			filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
			sort: { order: DESC, fields: frontmatter___date }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						date
						author
						summary
					}
				}
			}
		}
	}
`;
